/* CT Style Variables */
@import '../colors';
@import "../components/mixins";

$primary:       #053955;
$secondary:     darken(#43AC6A, 11%) !default;
$tertiary:      #3A95D2;
$light:         #E6E6E6;


$font-family-base: 'Open Sans', sans-serif;

/* Primary Page-Styling Variables */
$body-bg:                           #495058;
$body-color:                        #222;

$footer-bg:                         $primary;
$header-bg:                         white;
$navbar-bg:                         white;

$tooltip-icon-color:                $primary;
$tooltip-header-bg-color:           $primary;

$btn-pop-bg:                        $secondary;
$btn-mbl-color:                     #44413f;

/* Proccess Page Styling */
$process-page-themes: (
  "claim": $primary,
  "holder": $primary
);

$state-theme-colors: (
  "tertiary": $tertiary,
  "blue": #007bff,
  "danger": darken(#FF7D7D, 33%)
);
$theme-colors: map-merge($theme-colors, $state-theme-colors);

$dropdown-bg:                       #ffffff;
$dropdown-hover-bg:                 none;
$dropdown-divider-bg:               #777;

$dropdown-link-color:               #000;
$dropdown-link-hover-color:         #000;
$dropdown-link-hover-bg:            $gray-500;

$mobile-navbar-bg:                  #495058;
$static-page-header-color:           $primary;
$static-page-subtitle-color:        #333;

/* Image Size & Image Source Variables */
$banner-image-url:                  url('/images/CT_Spring_Banner.jpg');

$claim-header-logo-url:             url('/images/process_logo.png');
$claim-header-logo-height:          65px;
$claim-header-logo-width:           154px;

$holder-header-logo-url:             $claim-header-logo-url;
$holder-header-logo-height:          $claim-header-logo-height;
$holder-header-logo-width:           $claim-header-logo-width;

$home-logo-url:                     url('/images/header-logo.png');
$home-logo-width:                   400px;
$home-logo-height:                  71px;
$home-logo-resize-value-map:        (width: 71px,
                                     height: $home-logo-height,
                                     background-image: url('/images/state-seal.png'));

$treasurer-logo-url:                $home-logo-url;
$treasurer-logo-width:              $home-logo-width;
$treasurer-logo-height:             $home-logo-height;
$treasurer-logo-resize-value-map:   $home-logo-resize-value-map;

$footer-logo-url:                   url('/images/state-seal.png');
$footer-logo-width:                 100px;
$footer-logo-height:                100px;

$claimform-image-url:               url('/images/sample_claim_form.jpg');

$icon-email-url:                    none;
$icon-facebook-url:                 url('/images/facebook.png');
$icon-instagram-url:                none;
$icon-twitter-url:                  none;
$icon-youtube-url:                  none;
$icon-linkedin-url:                 none;

$social-icon-width:                 50px;
$social-icon-height:                50px;

$jumbotron-bg:                      hsla(16,6,6,.73);
$summary: #feefd8 !default;

$link-color:                       #0000FF;

#holder-summary{
  .card {
    .btn{
      background-color: $primary;
    }
  }
}


sws-faq-report sws-static-wrap.CT {


  #mailing-address-upd, #rectify-incorrect-submission, #permit-early-reporting, #how-reports, #late-fee, #one-payment, #ach-wire {
    @extend .card-title;
    @extend .mb-1;
  }

  .mailing-address-upd-details, .ach-wire-details {
    @extend .card-text;
  }

  .holders-address > .text-segment, .claims-address > .text-segment {
    @include extend-tab(1.5);
  }


  .read-general, .instruction .title {
    @extend .font-italic;
  }


  .how-reports-details .text-segment:after, .mailing-address-upd-details .text-segment:after {
     @extend .extend-linebreak;
  }

  .how-reports-details .text-block:after {
      @extend .extend-double-linebreak;
  }


  .how-reports-details .title, .link, .ach-id, .debit-text {
    @extend .extend-underline;
  }

  .revision-stamp {
    @extend .text-right;
  }

  .note::before {
    @extend .extend-linebreak;
  }

  .please-note {
    @extend .extend-linebreak;
  }

  .not-text, .please-note {
    @extend .font-weight-bold;
  }

  .address-line{
      @extend .extend-linebreak;
      margin-left: 2em;
  }


  .workflows, .holder-payment-methods {
    @extend .card-text;
    counter-reset: list;
  }

  .holder-payment-methods{
    @extend .mb-0;
  }

  .workflows .faq-claims, .workflows .faq-holders, .holder-payment-methods .instruction{
    counter-increment: list;
  }

  .workflows .faq-claims::marker, .workflows .faq-holders::marker, .holder-payment-methods .instruction::marker{
    counter-increment: list;
    content: counter(list) ")\A0\A0\A0\A0";
  }

  .lock-box {
    color: red;
  }

}

.green-header {
  background-color: #008000;
  font-size: 20px;
}